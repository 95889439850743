<template>
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='关键字：'>
                        <el-input v-model.trim='filters.keyWord' placeholder='输入姓名/手机/身份证号' clearable @input='() =>(filters.keyWord = filters.keyWord.replace(/\s/g,""))' @keyup.enter.native='getTableDataFun'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
            :data="tableData"
            style="width: 100%"
        >
            <el-table-column label="广东社会消防安全教育培训学员学习档案统计表" align='center'>
                <el-table-column label="取证率" align='center'>
                    <el-table-column type="index" label="序号" width="60" align='center'></el-table-column>
                    <el-table-column prop="Name" label="姓名" width="" align='center' show-overflow-tooltip></el-table-column>
                </el-table-column>
                <el-table-column :label="tableTitle" align='center'>
                    <el-table-column prop="PhoneNumber" label="手机号码" width="" align='center' show-overflow-tooltip></el-table-column>
                    <el-table-column prop="LearnPeriod" label="学习课时" width="" align='center' show-overflow-tooltip></el-table-column>
                </el-table-column>
                <el-table-column label="取证信息" align='center'>
                    <el-table-column prop="RegistrationDate" label="报名时间" :formatter="formatCreateTime1" width="" align='center' show-overflow-tooltip></el-table-column>
                    <el-table-column prop="StationName" label="岗位" width="" align='center' show-overflow-tooltip></el-table-column>
                    <el-table-column prop="TradeName" label="行业" width="" align='center' show-overflow-tooltip></el-table-column>
                    <el-table-column prop="CertificateDate" label="取证时间" :formatter="formatCreateTime2" width="" align='center' show-overflow-tooltip></el-table-column>
                    <el-table-column prop="LearnTime" label="学习时长" width="" align='center' show-overflow-tooltip></el-table-column>
                </el-table-column>
                <el-table-column label="模考信息" align='center'>
                    <el-table-column prop="province" label="学习视频记录" width="" align='center' show-overflow-tooltip>
                        <template scope="scope">
                            <el-button @click="viewStudyVideoRecord(scope.row)" type="text">查看</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="province" label="自由练习记录" width="" align='center' show-overflow-tooltip>
                        <template scope="scope">
                            <el-button @click="viewStudyRecord(scope.row)" type="text">查看</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="city" label="模拟考试记录" width="" align='center' show-overflow-tooltip>
                        <template scope="scope">
                            <el-button @click="viewExamRecord(scope.row)" type="text">{{scope.row.ExamineScore}}</el-button>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.pageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.dataCount"
        >
        </el-pagination>
        <!-- 自由练习记录/模拟考试记录 -->
        <el-dialog
            :title="studyORexam == 1 ? '自由练习记录' : studyORexam == 2 ? '模拟考试记录' : '学习视频记录'"
            :close-on-click-modal='true'
            :visible.sync="dialogVisible"
            width="65%"
        >
            <el-table
                border
                :data="recordData"
                highlight-current-row
                v-loading='recordLoading'
                style="width: 100%"
                v-if="studyORexam == 1"
                :cell-style="rowClass"
            >
                <el-table-column  type="index" label="序号" width="55" align='center'></el-table-column>
                <el-table-column prop='StudentDate' label='练习日期' width='' align='center' show-overflow-tooltip></el-table-column>
                <el-table-column prop='TopicCount' label='练习题数' width='' align='center' show-overflow-tooltip></el-table-column>
                <el-table-column prop='RightRate' label='正确率' width='' align='center' show-overflow-tooltip>
                    <template scope="scope">
                        <span>{{scope.row.RightRate ? scope.row.RightRate : 0}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-table
                border
                :data="recordData"
                highlight-current-row
                v-loading='recordLoading'
                style="width: 100%"
                v-if="studyORexam == 2"
                :cell-style="rowClass"
            >
                <el-table-column  type="index" label="序号" width="55" align='center'></el-table-column>
                <el-table-column prop='ExamDate' label='考试日期' width='' align='center' show-overflow-tooltip></el-table-column>
                <el-table-column prop='PaperName' label='试卷名称' width='' align='center' show-overflow-tooltip></el-table-column>
                <el-table-column prop='ExamTime' label='考试时长' width='' align='center' show-overflow-tooltip></el-table-column>
                <el-table-column prop='TotalScore' label='得分' width='' align='center' show-overflow-tooltip></el-table-column>
                <el-table-column prop='IsQualifiedFormat' label='是否及格' width='' align='center' show-overflow-tooltip>
                    <template scope="scope">
                        <span v-if="scope.row.IsQualifiedFormat == '及格'">{{scope.row.IsQualifiedFormat}}</span>
                        <span v-else-if="scope.row.IsQualifiedFormat == '不及格'" style="color: #f56c6c">{{scope.row.IsQualifiedFormat}}</span>
                        <span v-else>未知</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-table
                border
                :data="recordData"
                highlight-current-row
                v-loading='recordLoading'
                style="width: 100%"
                v-if="studyORexam == 3"
                :cell-style="rowClass"
            >
                <el-table-column  type="index" label="序号" width="55" align='center'></el-table-column>
                <el-table-column prop='StudentDate' label='学习日期' width='' align='center' show-overflow-tooltip></el-table-column>
                <el-table-column prop='TeachingVideoName' label='视频名称' width='' align='center' show-overflow-tooltip></el-table-column>
                <el-table-column prop='LearningDurationText' label='学习时长' :formatter="resetLearningDurationText" width='' align='center' show-overflow-tooltip></el-table-column>
                <el-table-column prop='PhotoCount' label='自拍数量' width='' align='center' show-overflow-tooltip></el-table-column>
            </el-table>
            <el-pagination
                @size-change="recordHandleSizeChange"
                @current-change="recordHandleCurrentChange"
                :current-page="recordPages.pageIndex"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="recordPages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="recordPages.dataCount"
            >
            </el-pagination>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import util from "../../../util/date";
import Qs from 'qs'
import {getStudentLearningCertificatePage,getCertificateExamineePaperResponseRecordPage,getCertificateExamIntelligentRecordPage,getCertificateStudentLearningRecordPage} from '@/api/api'
export default {
    components: { Toolbar },
    data(){
        return {
            buttonList: [],
            filters: {
                keyWord: ''
            },
            pages: {
                pageIndex: 1,
                pageSize: 20,
                dataCount: 0
            },
            listLoading: false,
            tableTitle: "80%",
            tableData: [],
            currentRow: "",
            currentRowLenrollID: "",
            // 自由练习记录/模拟考试记录
            dialogVisible:false,
            recordData:[],
            recordLoading:false,
            studyORexam: 1,
            recordPages:{
                pageIndex:1,
                pageSize:10,
                dataCount:0
            }
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this,item);
        },
        // 获取列表数据
        getData() {
            var params = {
                keyWord: this.filters.keyWord,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize
            }
            this.listLoading = true
            getStudentLearningCertificatePage(params).then(res => {
                this.listLoading = false
                if(res.data.Success) {
                    this.tableTitle = res.data.Response.PassRate ? res.data.Response.PassRate : "80%"
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                } else {
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {
                this.listLoading = false
                this.$message.error(res.data.Message)
            })
        },
        // 查询
        getTableDataFun() {
            this.pages.pageIndex = 1
            this.getData()
        },
        // 选择当前行
        selectCurrentRow(row) {
            this.currentRow = row
        },
        // 学习视频记录
        viewStudyVideoRecord(row) {
            this.studyORexam = 3
            this.recordPages.pageIndex = 1
            this.currentRowLenrollID = row.LearningEnrollInfoID
            this.recordDetail(this.currentRowLenrollID)
        },
        // 自由练习记录
        viewStudyRecord(row) {
            this.studyORexam = 1
            this.recordPages.pageIndex = 1
            this.currentRowLenrollID = row.LearningEnrollInfoID
            this.recordDetail(this.currentRowLenrollID)
        },
        // 模拟考试记录
        viewExamRecord(row) {
            this.studyORexam = 2
            this.recordPages.pageIndex = 1
            this.currentRowLenrollID = row.LearningEnrollInfoID
            this.recordDetail(this.currentRowLenrollID)
        },
        // 自由练习记录/模拟考试记录/学习视频记录
        recordDetail(id){
            var params = {
                LearningEnrollInfoID: id,
                pageIndex:this.recordPages.pageIndex,
                pageSize:this.recordPages.pageSize
            }
            this.dialogVisible = true
            this.recordLoading = true
            var getRecordApi
            if(this.studyORexam == 1) {
                getRecordApi = getCertificateExamIntelligentRecordPage
            } else if(this.studyORexam == 2) {
                getRecordApi = getCertificateExamineePaperResponseRecordPage
            } else if(this.studyORexam == 3) {
                getRecordApi = getCertificateStudentLearningRecordPage
            }
            getRecordApi(params).then(res => {
                if(res.data.Success){
                    this.recordData = res.data.Response.Data
                    this.recordPages.dataCount = res.data.Response.DataCount
                }else{
                    this.$Message.error(res.data.Message)
                }
                this.recordLoading = false
            })
        },
        // 分页
        handleSizeChange(value) {
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value) {
            this.pages.pageIndex = value
            this.getData()
        },
        // 自由练习记录/模拟考试记录分页处理
        recordHandleSizeChange(value){
            this.recordPages.pageSize = value
            this.recordDetail(this.currentRowLenrollID)
        },
        recordHandleCurrentChange(value){
            this.recordPages.pageIndex = value
            this.recordDetail(this.currentRowLenrollID)
        },
        rowClass({columnIndex}){
            return 'padding:8px 0!important;'
        },
        resetLearningDurationText: function(row, column) {
            return !row.LearningDurationText || row.LearningDurationText == ""
            ? 0
            : row.LearningDurationText.split(".")[0];
        },
        formatCreateTime: function(row, column) {
            return !row.ExamDate || row.ExamDate == ""
            ? ""
            : util.formatDate.format(new Date(row.ExamDate), "yyyy-MM-dd hh:mm:ss");
        },
        formatCreateTime1: function(row, column) {
            return !row.RegistrationDate || row.RegistrationDate == ""
            ? ""
            : util.formatDate.format(new Date(row.RegistrationDate), "yyyy-MM-dd hh:mm:ss");
        },
        formatCreateTime2: function(row, column) {
            return !row.CertificateDate || row.CertificateDate == ""
            ? ""
            : util.formatDate.format(new Date(row.CertificateDate), "yyyy-MM-dd");
        },
    },
    created() {
        this.getData()
    },
    mounted() {
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>

</style>